import React, { useEffect, useState } from "react";

function Home(){
  
  
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-12-1`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };


  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());
  var firstDigit = String(timeLeft.days).charAt(0);
  var secondDigit = String(timeLeft.days).charAt(1);

  var FIRST = Number(firstDigit);

  var firstDigitx = "images/numbers/" + firstDigit + ".jpg";
  var secondDigitx = "images/numbers/" + secondDigit + ".jpg";


  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div classNameName="App">

        {/*  This site was created by BelleIT.net  */}
        {/*  WordPress was greatly considered in the creation of this site  */}
        {/*  Last Published: Thu Oct 22 2020 19:29:17 GMT+0000 (Coordinated Universal Time)  */}
        <meta charSet="utf-8" />
        <title>Real Home</title>
        <meta content="Real Home" property="og:title" />
        <meta content="Real Home" property="twitter:title" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="Webflow" name="generator" />
        <link href="css/normalize.css" rel="stylesheet" type="text/css" />
        <link href="css/webflow.css" rel="stylesheet" type="text/css" />
        <link href="css/perspectivebfa.webflow.css" rel="stylesheet" type="text/css" />
        {/* [if lt IE 9]><![endif] */}
        <link href="images/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <link href="images/webclip.png" rel="apple-touch-icon" />
        <div data-collapse="medium" data-animation="default" data-duration={400} role="banner" className="navigation w-nav">
          <div className="navigation-items"><a href="/" aria-current="page" className="logo-link w-nav-brand w--current">Alia X</a>
            <div className="navigation-wrap">
              <nav role="navigation" className="navigation-items w-nav-menu"><a href="/" className="navigation-item w-nav-link">About</a></nav>
              <div className="menu-button w-nav-button"><img src="images/menu-icon_1menu-icon.png" width={22} alt="" className="menu-icon" /></div>
            </div>
          </div>
          
        </div>
        <div className="section">
        
          <div className="container">
            <div className="w-layout-grid about-intro-grid"></div>
            <div id="w-node-7dee623c62c1-5d957e38" class="about-head-text-wrap">

            <div class='webflow-link w-inline-block' style={{justifyContent: 'center'}}  >


        <img src="images/IMG_2491.JPG" height="300px" alt="" />

</div>
          <div class="label cc-about-light">Countdown until launch:
          
          </div>

          <div className="section">
        
          <div className="container">
            <div id="w-node-7dee623c62c1-5d957e38" class="about-head-text-wrap">
          <div class='webflow-link w-inline-block' style={{justifyContent: 'center'}}  >
  
    {/*<img height="400px" src={firstDigitx}/><img height="400px" src={secondDigitx}/>*/}
  
    
  
  
</div>
</div>
</div>
</div>

              </div>
            
            <a href="" className="navigation-item w-nav-link">
      {timerComponents.length ? timerComponents : <p>Live!</p>}</a>
            
          </div>
          
        </div>
        <div className="section">
          <div className="container">
            <div className="w-layout-grid about-intro-grid"></div>
            
            
          </div>
          
        </div>

        
        <div className="footer-wrap">
          <div>{/*<a href="https://webflow.com/" target="_blank" class="webflow-link w-inline-block">*/}<img src="images/webflow-w-small2x_1webflow-w-small2x.png" width={15} alt="" className="webflow-logo-tiny" /><div className="paragraph-tiny"></div></div>
          <a href="https://www.instagram.com/aliaxart/" target="_blank" className="footer-item">Instagram</a></div>
        {/* [if lte IE 9]><![endif] */}
      </div>
   

    
       

  );
  }


  export default Home;
