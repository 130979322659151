import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
//import Nav from './components/nav'
import Home from './components/home'
import { Router,navigate} from "@reach/router"

class App extends Component {
  render(){
  return (
    <div className="App">
      <Router>
              <Home path="/"/>
              </Router>
    </div>
  );
}
}

export default App;
